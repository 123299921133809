import React from "react";

const Section = (props) => {
  if (props.visibility !== "visible") {
    return (<></>);
  }
  const className = (() => {
    let cN = [];
    if (typeof props.layoutGridColumnSpan !== "undefined") {
      cN.push("col-span-"+props.layoutGridColumnSpan);
    }
    if (props.cssClass.length) {
      cN.push(props.cssClass);
    }
    if (cN.length) {
      return cN.join(" ");
    }
    return undefined;
  })();
  return (<h3 className={ className }>{ props.label }</h3>);
};

export default Section;
